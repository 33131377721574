/* eslint-disable no-console */

import { register } from "register-service-worker";

import { sendMessage } from "@/components/send-message";

if (process.env.NODE_ENV === "production") {
	register(`${process.env.BASE_URL}service-worker.js`, {
		ready() {
			console.log(
				"App is being served from cache by a service worker.\n" +
					"For more details, visit https://goo.gl/AFskqB",
			);
		},
		registered() {
			console.log("Service worker has been registered.");
		},
		cached() {
			console.log("Content has been cached for offline use.");
		},
		updatefound() {
			console.log("New content is downloading.");
		},
		updated(registration) {
			console.log("New content is available; please refresh.");
			sendMessage("A new version of the app is available.", 60 * 1000, [
				{
					side: "end",
					text: "Update",
					handler: () => {
						registration.waiting?.postMessage({ type: "SKIP_WAITING" });
					},
				},
			]);

			navigator.serviceWorker.addEventListener("controllerchange", () => {
				window.location.reload();
			});
		},
		offline() {
			console.log("No internet connection found. App is running in offline mode.");
		},
		error(error) {
			console.error("Error during service worker registration:", error);
		},
	});
}
